import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
let SubscriptionCard = class SubscriptionCard extends Vue {
};
SubscriptionCard = __decorate([
    Component({ name: 'SubscriptionDetails' })
], SubscriptionCard);
export { SubscriptionCard };
export default SubscriptionCard;
