import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let Callout = class Callout extends Vue {
};
Callout = __decorate([
    Component({ name: 'Callout' })
], Callout);
export { Callout };
export default Callout;
