import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { CardsRepositoryType } from '@profile/contracts';
import { EModal } from '@contract/modal';
import { DateTimeType, ModalType } from '@movecloser/front-core';
import { Inject } from '@plugin/inversify';
import SubscriptionCard from '../SubscriptionDetails/_partials/SubscriptionCard.vue';
let MyCards = class MyCards extends Vue {
    constructor() {
        super(...arguments);
        this.activeCardId = null;
        this.cards = null;
        this.isLoading = false;
        this.icon = {
            VISA: require('@/assets/images/logotypes/visa.svg'),
            Mastercard: require('@/assets/images/logotypes/mastercard.svg')
        };
    }
    get activeCard() {
        var _a, _b;
        return (_b = (_a = this.cards) === null || _a === void 0 ? void 0 : _a.find(c => c.isActive)) !== null && _b !== void 0 ? _b : null;
    }
    get daysLeft() {
        if (!this.activeCard) {
            return null;
        }
        let days = 0;
        if (this.activeCard.expireAt) {
            days = this.dateTime.difference(this.activeCard.expireAt);
        }
        if (!days) {
            return null;
        }
        return Math.floor(days / 60 / 60 / 24);
    }
    get hasCards() {
        var _a;
        return !!((_a = this.cards) === null || _a === void 0 ? void 0 : _a.length);
    }
    get hasManyCards() {
        return !!this.cards && this.cards.length > 1;
    }
    get hasActiveCard() {
        return !!this.activeCard;
    }
    get isWaitingForAuth() {
        return this.$store.getters['profile/isWaitingForAuth'];
    }
    async toggle(id) {
        this.isLoading = true;
        try {
            await this.cardsRepository.update(id);
            this.cards = await this.cardsRepository.load();
        }
        catch (error) {
            this.$logger(error, 'error');
        }
        finally {
            this.isLoading = false;
        }
    }
    onRemove(id, number) {
        this.modalConnector.open(EModal.Info, {
            body: this.$t('modules.profile.views.my-cards.modal.body', { number }),
            buttons: [{
                    label: this.$t('modules.profile.views.my-cards.modal.button'),
                    handler: () => {
                        this.onRemoveConfirmed(id);
                    },
                    className: 'btn-cta'
                }],
            title: this.$t('modules.profile.views.my-cards.modal.title')
        });
    }
    async onRemoveConfirmed(id) {
        this.isLoading = true;
        try {
            this.modalConnector.close();
            await this.cardsRepository.remove(id);
            this.cards = await this.cardsRepository.load();
        }
        catch (error) {
            this.$logger(error, 'error');
        }
        finally {
            this.isLoading = false;
        }
    }
    onChange() {
        if (!this.activeCardId) {
            return;
        }
        this.toggle(this.activeCardId);
    }
    async loadCards() {
        try {
            this.cards = await this.cardsRepository.load();
        }
        catch (error) {
            this.$logger(error, 'error');
        }
        finally {
            this.isLoading = false;
        }
    }
    onAuthChange() {
        if (!this.isWaitingForAuth) {
            this.loadCards();
        }
    }
    onActiveCardChange() {
        var _a, _b;
        this.activeCardId = (_b = (_a = this.activeCard) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null;
    }
};
__decorate([
    Inject(CardsRepositoryType)
], MyCards.prototype, "cardsRepository", void 0);
__decorate([
    Inject(DateTimeType)
], MyCards.prototype, "dateTime", void 0);
__decorate([
    Inject(ModalType)
], MyCards.prototype, "modalConnector", void 0);
__decorate([
    Watch('isWaitingForAuth')
], MyCards.prototype, "onAuthChange", null);
__decorate([
    Watch('activeCard')
], MyCards.prototype, "onActiveCardChange", null);
MyCards = __decorate([
    Component({
        name: 'MyCards',
        components: { SubscriptionCard },
        mounted() {
            this.onAuthChange();
        }
    })
], MyCards);
export { MyCards };
export default MyCards;
