import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { DateTimeType, ModalType } from '@movecloser/front-core';
import { EModal } from '@contract/modal';
import { Inject } from '@plugin/inversify';
import { SubscriptionPeriod, SubscriptionsStatus, TransactionsRepositoryType } from '@subscription/contracts';
import { SubscriptionCard } from './_partials/SubscriptionCard.vue';
require('dayjs/locale/pl');
/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
let SubscriptionDetails = class SubscriptionDetails extends Vue {
    get canBeCancelled() {
        return this.subscription.isPaid && this.subscription.renewable && this.subscription.status === SubscriptionsStatus.Paid;
    }
    get canBeContinued() {
        return !this.subscription.isPaid && !this.newSubscription && !this.subscription.isPending();
    }
    get canBeRestored() {
        return this.subscription.isPaid &&
            this.subscription.status === SubscriptionsStatus.DaysLeft &&
            this.subscription.period === SubscriptionPeriod.Monthly;
    }
    get isCancelled() {
        return this.subscription.period === SubscriptionPeriod.Monthly && this.subscription.status === SubscriptionsStatus.DaysLeft;
    }
    get promotion() {
        if (!this.subscription.promotion) {
            return null;
        }
        return this.subscription.promotion.products[0];
    }
    get leftDays() {
        let duration = 0;
        if (this.subscription.endedAt) {
            duration = this.dateTime.difference(this.subscription.endedAt);
        }
        if (!duration) {
            return duration;
        }
        return Math.floor(duration / 60 / 60 / 24);
    }
    continueTransaction() {
        if (!this.newSubscription) {
            this.$emit('continue');
            return;
        }
        this.$emit('upgrade', this.subscription.id, this.newSubscription.id);
    }
    cancelSubscription() {
        this.modalConnector.open(EModal.Info, {
            body: this.$t('modules.profile.views.my-subscription.modal.body', { date: this.leftDays }),
            buttons: [{
                    label: this.$t('modules.profile.views.my-subscription.modal.button'),
                    handler: () => {
                        this.onSubscriptionCancel();
                    },
                    className: 'btn-cta'
                }],
            title: this.$t('modules.profile.views.my-subscription.modal.title')
        });
    }
    async upgradeSubscription() {
        try {
            const newPeriod = await this.transactions.beginUpgrade(this.subscription.id, SubscriptionPeriod.Annual);
            this.$emit('upgrade', newPeriod.uuid, newPeriod.newPeriodUuid);
        }
        catch (e) {
            this.$logger(e, 'warn');
        }
    }
    restoreSubscription() {
        this.modalConnector.open(EModal.Info, {
            body: this.$t('modules.profile.views.my-subscription.modal-restore.body', { date: this.leftDays }),
            buttons: [{
                    label: this.$t('modules.profile.views.my-subscription.modal-restore.button'),
                    handler: () => {
                        this.$emit('restore');
                    },
                    className: 'btn-cta'
                }],
            title: this.$t('modules.profile.views.my-subscription.modal-restore.title')
        });
    }
    getLeftText() {
        if (this.subscription.renewable) {
            const date = this.dateTime.parseToFormat(this.subscription.endedAt, 'DD MMM YYYY');
            return this.$t('modules.profile.views.my-subscription.renewable.renewal', { date });
        }
        if (this.leftDays <= 0) {
            return this.$t('modules.profile.views.my-subscription.one-time.expired');
        }
        return this.$tc('modules.profile.views.my-subscription.one-time.left', this.leftDays, { date: this.leftDays });
    }
    onSubscriptionCancel() {
        this.$emit('cancel');
    }
};
__decorate([
    Prop({
        type: Object,
        required: true
    })
], SubscriptionDetails.prototype, "subscription", void 0);
__decorate([
    Prop({
        type: Object,
        required: false
    })
], SubscriptionDetails.prototype, "newSubscription", void 0);
__decorate([
    Inject(DateTimeType)
], SubscriptionDetails.prototype, "dateTime", void 0);
__decorate([
    Inject(ModalType)
], SubscriptionDetails.prototype, "modalConnector", void 0);
__decorate([
    Inject(TransactionsRepositoryType)
], SubscriptionDetails.prototype, "transactions", void 0);
SubscriptionDetails = __decorate([
    Component({
        name: 'SubscriptionDetails',
        components: { SubscriptionCard },
        created() {
            dayjs.locale('pl'); // fix locale
        }
    })
], SubscriptionDetails);
export { SubscriptionDetails };
export default SubscriptionDetails;
