import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SubscriptionCard } from './_partials/SubscriptionCard.vue';
/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
let NoneSubscription = class NoneSubscription extends Vue {
};
__decorate([
    Prop({ type: Boolean, required: true })
], NoneSubscription.prototype, "hasPendingOrder", void 0);
__decorate([
    Prop({ type: Object, required: true })
], NoneSubscription.prototype, "paywallLimits", void 0);
NoneSubscription = __decorate([
    Component({
        name: 'NoneSubscription',
        components: { SubscriptionCard }
    })
], NoneSubscription);
export { NoneSubscription };
export default NoneSubscription;
