// Copyright © 2021 Move Closer
import { defineComponent } from '@vue/composition-api';
import { alertProps, useAlert } from './Alert.hooks';
/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const Alert = defineComponent({
    name: 'Alert',
    props: alertProps,
    setup: (props, ctx) => {
        return useAlert(props, ctx);
    },
    template: `
    <div v-if="isOpen" class="alert" role="alert"
         :class="['alert-' + theme, { 'alert-dismissible': dismissible }]">
      <!-- "Close" button -->
      <D24Button v-if="dismissible" type="button" class="btn-close"
                 @click="close" data-dismiss="alert" aria-label="Close" >
        <D24Icon :name="closeIcon" size="small" />
      </D24Button>

      <div class="d-flex align-items-center mb-0">
        <!-- Additional icon -->
        <div v-if="showIcon && iconToDisplay" class="flex-00-auto mr-1">
          <D24Icon :name="iconToDisplay" size="small" />
        </div>

        <!-- Main content -->
        <div class="flex-fill">
          <slot />
        </div>
      </div>
    </div>
  `
});
