import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ModalType } from '@movecloser/front-core';
import { Alert, AlertTheme } from '@component/Alert';
import { Callout } from '@component/Callout/Callout.vue';
import { Inject } from '@plugin/inversify';
import { PaywallServiceType } from '@service/paywall';
import { localStorage } from '@support/local-storage';
import { Screen } from '@component/Screen';
import { SubscriptionsRepositoryType } from '@subscription/contracts';
import { NoneSubscription } from '../../../components/SubscriptionDetails/NoneSubscription.vue';
import { SubscriptionDetails } from '../../../components/SubscriptionDetails/SubscriptionDetails.vue';
import { MyCards } from '../../../components/MyCards';
import { SiteServiceType } from '@service/site';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
let SubscriptionView = class SubscriptionView extends Vue {
    constructor() {
        super(...arguments);
        this.alert = null;
        this.isLoading = false;
        this.paywallLimits = null;
        this.prevSubscription = null;
        this.showWelcomeCallout = false;
    }
    get me() {
        return this.$store.getters['profile/me'];
    }
    get siteName() {
        return this.siteService.getActiveSite().name;
    }
    get prevSubscriptionId() {
        var _a, _b;
        return (_b = (_a = this.me) === null || _a === void 0 ? void 0 : _a.prevSubscription) !== null && _b !== void 0 ? _b : undefined;
    }
    get subscription() {
        return this.$store.getters['profile/subscription'];
    }
    closeCallout() {
        this.showWelcomeCallout = false;
        localStorage.set('isTutorialCompleted', 'true');
    }
    cancelSubscription() {
        var _a, _b;
        if (!this.me) {
            return;
        }
        this.subscriptions.cancel((_b = (_a = this.me) === null || _a === void 0 ? void 0 : _a.prevSubscription) !== null && _b !== void 0 ? _b : this.me.subscription).then(() => {
            this.alert = {
                theme: AlertTheme.Success,
                message: this.$t('modules.profile.views.my-subscription.alert.success'),
                useDefaultIcon: false,
                dismissible: true,
                autoTimeout: 5000
            };
            this.$emit('refresh');
        }).catch(e => {
            console.debug(e);
            this.alert = {
                theme: AlertTheme.Warning,
                message: e.message,
                useDefaultIcon: false,
                dismissible: true,
                autoTimeout: 5000
            };
        }).finally(() => {
            this.$emit('refresh');
            this.modalConnector.close();
            this.isLoading = false;
        });
    }
    restoreSubscription() {
        if (!this.me) {
            return;
        }
        this.subscriptions.restore(this.me.subscription).then(() => {
            this.alert = {
                theme: AlertTheme.Success,
                message: this.$t('modules.profile.views.my-subscription.alert.restore'),
                useDefaultIcon: false,
                dismissible: true,
                autoTimeout: 5000
            };
            this.$emit('refresh');
        }).catch(e => {
            console.debug(e);
            this.alert = {
                theme: AlertTheme.Warning,
                message: e.message,
                useDefaultIcon: false,
                dismissible: true,
                autoTimeout: 5000
            };
        }).finally(() => {
            this.$emit('refresh');
            this.modalConnector.close();
            this.isLoading = false;
        });
    }
    continueOrder() {
        if (!this.subscription) {
            return;
        }
        this.$router.push({
            name: 'subscription.choose.payment',
            params: { term: this.subscription.id }
        });
    }
    upgradeSubscription(oldId, newId) {
        if (!this.subscription) {
            return;
        }
        this.$router.push({
            name: 'subscription.choose.payment.upgrade',
            params: { oldTerm: oldId, term: newId, first: '1' }
        });
    }
    getPaywallData() {
        if (!this.subscription || this.subscription.isPaid) {
            return;
        }
        this.isLoading = true;
        this.paywallService.checkAccess('/').then(response => {
            this.paywallLimits = {
                used: response.status.limit - response.status.used,
                limit: response.status.limit
            };
        }).finally(() => {
            this.isLoading = false;
        });
    }
    async loadPreviousSubscription() {
        var _a;
        if (!this.prevSubscriptionId || ((_a = this.subscription) === null || _a === void 0 ? void 0 : _a.isPaid)) {
            return;
        }
        this.prevSubscription = await this.subscriptions.load(this.prevSubscriptionId);
    }
    onSubscriptionLoad(sub) {
        if (sub) {
            this.getPaywallData();
        }
    }
};
__decorate([
    Inject(ModalType)
], SubscriptionView.prototype, "modalConnector", void 0);
__decorate([
    Inject(PaywallServiceType)
], SubscriptionView.prototype, "paywallService", void 0);
__decorate([
    Inject(SubscriptionsRepositoryType)
], SubscriptionView.prototype, "subscriptions", void 0);
__decorate([
    Inject(SiteServiceType)
], SubscriptionView.prototype, "siteService", void 0);
__decorate([
    Watch('prevSubscriptionId')
], SubscriptionView.prototype, "loadPreviousSubscription", null);
__decorate([
    Watch('subscription')
], SubscriptionView.prototype, "onSubscriptionLoad", null);
SubscriptionView = __decorate([
    Component({
        name: 'SubscriptionView',
        components: {
            MyCards,
            Alert,
            Callout,
            Screen,
            NoneSubscription,
            SubscriptionDetails
        },
        created() {
            this.getPaywallData();
            this.loadPreviousSubscription();
        },
        beforeMount() {
            if (!localStorage.get('isTutorialCompleted')) {
                this.showWelcomeCallout = true;
            }
        }
    })
], SubscriptionView);
export { SubscriptionView };
export default SubscriptionView;
