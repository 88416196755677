// Copyright © 2021 Move Closer
import { computed, ref } from '@vue/composition-api';
import { AlertTheme } from './Alert.contracts';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const alertProps = {
    autoTimeout: {
        type: Number,
        required: false,
        default: null
    },
    dismissible: {
        type: Boolean,
        required: false,
        default: false
    },
    icon: {
        type: String,
        required: false,
        default: null
    },
    showIcon: {
        type: Boolean,
        required: false,
        default: true
    },
    theme: {
        type: String,
        required: false,
        default: AlertTheme.Info
    },
    useDefaultIcon: {
        type: Boolean,
        required: false,
        default: true
    }
};
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const useAlert = (props, ctx) => {
    const isOpen = ref(true);
    const closeAlert = () => {
        isOpen.value = false;
        ctx.emit('close');
    };
    const iconToDisplay = computed(() => {
        // TODO add proper icons
        if (!props.icon && props.useDefaultIcon) {
            switch (props.theme) {
                case AlertTheme.Danger:
                    return 'close';
                case AlertTheme.Info:
                    return 'info';
                case AlertTheme.Primary:
                    return 'info';
                case AlertTheme.Success:
                    return 'check';
                case AlertTheme.Warning:
                    return 'exclamation';
            }
        }
        return props.icon;
    });
    if (props.autoTimeout) {
        setTimeout(() => closeAlert(), props.autoTimeout);
    }
    return { close: closeAlert, closeIcon: 'close', iconToDisplay, isOpen };
};
