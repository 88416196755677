// Copyright © 2021 Move Closer
/**
 * Set of helper methods for working with the browser's `localStorage`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const localStorage = {
    /**
     * Resolves the value for the given key.
     * Returns `null` if the key is not set.
     *
     * @param key - The key which value is to be resolved.
     */
    get(key) {
        if (typeof window === 'undefined') {
            return null;
        }
        return window.localStorage.getItem(key);
    },
    /**
     * Checks if the given key has ever been set.
     *
     * @param key - The key that is to be checked.
     */
    isSet(key) {
        if (typeof window === 'undefined') {
            return false;
        }
        return window.localStorage.getItem(key) !== null;
    },
    /**
     * Removes the given key.
     *
     * @param key - The key that is to be removed.
     */
    remove(key) {
        if (typeof window === 'undefined') {
            return;
        }
        window.localStorage.removeItem(key);
    },
    /**
     * Sets the value for the given key.
     *
     * @param key - The key that is to be modified.
     * @param value - The target value for the key.
     */
    set(key, value) {
        if (typeof window !== 'undefined') {
            window.localStorage.setItem(key, value);
        }
    }
};
